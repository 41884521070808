import InquiryPrint from '../inquiryPrint.vue'
import { getNowDate } from '@/utils/index'
import { uploadPdfFile } from '@/api/mail/mail.js'

const mixin = {
  components: {
    InquiryPrint,
  },
  methods: {
    generatePdf(id) {
      this.isShowPrint = true
      this.$refs.InquiryPrint.getPreviewPDF(id)
    },
    onPrint(id) {
      this.isShowPrint = true
      this.$refs.InquiryPrint.getPrintPDF(id)
    },
    // html 转成 PDF
    domToPdf(customerEmail, toQuationList, pageNum) {
      let title = `quotation-${getNowDate()}`
      setTimeout(() => {
        this.getPdfMultiplePage(title, 'inquiryprint', false, pageNum).then(
          (res) => {
            //res拿到base64的pdf
            let pdfBase64Str = res
            let title = `quotation-${getNowDate()}`
            var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
            var formdata = new FormData()
            formdata.append('file', myfile) // 文件对象
            formdata.append('flagSaveName', 1)
            formdata.append('source', 1)
            formdata.append('uploadType', 1)
            //该uploadMy为接口，直接以formdata格式传给后台
            uploadPdfFile(formdata)
              .then((res) => {
                let file = {
                  enclosureName: res.data.fileName,
                  fileId: res.data.fileId,
                  enclosureSize: res.data.size,
                  enclosureUrl: res.data.path,
                  suffix: res.data.suffix,
                  uploadType: 1,
                  mailEnclosureId: res.data.fileId,
                  source: 1,
                }
                let url = ''
                if (this.mailDetail) {
                  // 从邮件直接生成报价单
                  url = `${
                    window.location.origin
                  }/#/write?from=business&mailId=${
                    this.mailDetail.baseInfo.mailId
                  }&file=${JSON.stringify([file])}&email=${
                    this.mailDetail.baseInfo.sendEmail
                  }`
                } else {
                  url = `${
                    window.location.origin
                  }/#/write?from=business&file=${JSON.stringify(
                    file
                  )}&email=${customerEmail}`
                }
                // this.$router.push({
                //   path: '/order/quotations-list',
                // })
                this.utils.openWindow(url)
              })
              .catch((err) => {})
              .finally(() => {
                this.isShowPrint = false
              })
          }
        )
      }, 600)
    },

    downloadPdf(id) {
      this.$refs.InquiryPrint.downloadPdf(id)
    },

    domToPdfPrint(customerEmail, toQuationList, pageNum) {
      let self = this
      let title = `quotation-${getNowDate()}`
      setTimeout(() => {
        this.getPdfMultiplePage(title, 'inquiryprint', false, pageNum).then(
          (res) => {
            //res拿到base64的pdf
            let pdfBase64Str = res
            let title = `quotation-${getNowDate()}`
            var myfile = this.dataURLtoFile(pdfBase64Str, title + '.pdf') //调用一下下面的转文件流函数
            var formdata = new FormData()
            formdata.append('file', myfile) // 文件对象
            formdata.append('flagSaveName', 1)
            formdata.append('source', 1)
            formdata.append('uploadType', 1)
            //该uploadMy为接口，直接以formdata格式传给后台
            uploadPdfFile(formdata)
              .then((res) => {
                let routeData = self.$router.resolve({
                  path: `/preview-file/index?name=${res.data.fileName}&url=${res.data.path}`,
                })
                window.open(routeData.href, '_blank')
              })
              .catch((err) => {})
              .finally(() => {
                this.isShowPrint = false
              })
          }
        )
      }, 600)
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
  },
}

export default mixin
